let timer;
let ip;
if (process.env.DEV) {
  ip = process.env.VUE_APP_API_URL;
} else {
  ip = process.env.VUE_APP_API_URL;
}
export default {
  // auth start
  async login(context, payload) {
    return context.dispatch("auth", {
      ...payload,
    });
  },
  async tryLogin(context) {
    const token = localStorage.getItem("token");
    const userID = localStorage.getItem("userID");
    const role = localStorage.getItem("role");
    const phone = localStorage.getItem("phone");
    const phoneCode = localStorage.getItem("phoneCode");
    const email = localStorage.getItem("email");
    const image = localStorage.getItem("image");
    const firstName = localStorage.getItem("firstName");
    const lastName = localStorage.getItem("lastName");
    const tokenExpiration = localStorage.getItem("tokenExpiration");

    if (!token) {
      console.log("token not found");
      return;
    }
    const expiresIn = +parseInt(tokenExpiration) - new Date().getTime();

    if (expiresIn < 0) {
      console.log("expired");
      return;
    }

    timer = setTimeout(() => {
      context.dispatch("autoLogout");
    }, expiresIn);

    if (token && userID) {
      context.commit("setUser", {
        id: userID,
        token,
        firstName,
        lastName,
        phone,
        phoneCode,
        email,
        image,
        role,
      });
      return;
    }
  },
  async hasExpired(context) {
    const tokenExpiration = localStorage.getItem("tokenExpiration");
    const expiresIn = +parseInt(tokenExpiration) - new Date().getTime();

    if (expiresIn < 0) {
      context.dispatch("logout");
      return true;
    }
    return false;
  },
  async logout(context) {
    let url = `${ip}/api/admin/logout`;
    const token = localStorage.getItem("token");
    await fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });

    localStorage.removeItem("token");
    localStorage.removeItem("userID");
    localStorage.removeItem("role");
    localStorage.removeItem("email");
    localStorage.removeItem("image");
    localStorage.removeItem("firstName");
    localStorage.removeItem("lastName");
    localStorage.removeItem("phone");
    localStorage.removeItem("phoneCode");
    localStorage.removeItem("tokenExpiration");
    clearTimeout(timer);
    context.commit("setUser", {
      token: null,
      id: null,
      role: null,
      email: null,
      image: null,
      phone: null,
      phoneCode: null,
      firstName: null,
      lastName: null,
    });
    return;
  },
  async auth(context, payload) {
    let url = `${ip}/admin/login`;

    const response = await fetch(url, {
      method: "POST",
      headers: {
        "Content-type": "application/json",
      },
      body: JSON.stringify({
        email: payload.email,
        password: payload.password,
      }),
    });

    const responseData = await response.json();
    if (!response.ok) {
      const error = new Error(responseData.message || "Failed to Authenticate");
      throw error;
    }

    const expiresIn = +responseData.expiresIn * 1000;
    const expirationDate = new Date().getTime() + expiresIn;
    localStorage.setItem("token", responseData.token);
    localStorage.setItem("userID", responseData.id);
    localStorage.setItem("role", responseData.role);
    localStorage.setItem("firstName", responseData.firstName);
    localStorage.setItem("lastName", responseData.lastName);
    localStorage.setItem("email", responseData.email);
    localStorage.setItem("image", responseData.image);
    localStorage.setItem("phone", responseData.phone);
    localStorage.setItem("phoneCode", responseData.phoneCode);
    localStorage.setItem("tokenExpiration", expirationDate);

    timer = setTimeout(() => {
      context.dispatch("autoLogout");
    }, expiresIn);
    context.commit("setUser", {
      ...responseData,
    });
  },
  // auth ends
  async updateProfile(context, payload) {
    let url = `${ip}/admin/update`;
    const token = localStorage.getItem("token");
    const response = await fetch(url, {
      method: "PUT",
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        ...payload,
      }),
    });

    const responseData = await response.json();
    if (!response.ok) {
      const error = new Error(responseData.message || "Failed to Authenticate");
      throw error;
    }
    localStorage.setItem("userID", responseData.id);
    localStorage.setItem("role", responseData.role);
    localStorage.setItem("firstName", responseData.firstName);
    localStorage.setItem("lastName", responseData.lastName);
    localStorage.setItem("email", responseData.email);
    localStorage.setItem("image", responseData.image);
    localStorage.setItem("phone", responseData.phone);
    localStorage.setItem("phoneCode", responseData.phoneCode);

    context.commit("setUser", {
      ...responseData,
      token: token,
    });

    return;
  },
  async updateEmail(context, payload) {
    let url = `${ip}/admin/update/email`;
    const token = localStorage.getItem("token");
    const response = await fetch(url, {
      method: "PUT",
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        ...payload,
      }),
    });

    const responseData = await response.json();
    if (!response.ok) {
      const error = new Error(responseData.message || "Failed to Authenticate");
      throw error;
    }
    localStorage.setItem("userID", responseData.id);
    localStorage.setItem("role", responseData.role);
    localStorage.setItem("firstName", responseData.firstName);
    localStorage.setItem("lastName", responseData.lastName);
    localStorage.setItem("email", responseData.email);
    localStorage.setItem("image", responseData.image);
    localStorage.setItem("phone", responseData.phone);
    localStorage.setItem("phoneCode", responseData.phoneCode);

    context.commit("setUser", {
      ...responseData,
      token: token,
    });

    return;
  },
  async updatePassword(context, payload) {
    let url = `${ip}/admin/update/password`;
    const token = localStorage.getItem("token");
    const response = await fetch(url, {
      method: "PUT",
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        ...payload,
      }),
    });

    const responseData = await response.json();
    if (!response.ok) {
      const error = new Error(responseData.message || "Failed to Authenticate");
      throw error;
    }
    return;
  },
  //
  async forgotPassword(context, payload) {
    let url = `${ip}/admin/forgot-password`;
    const response = await fetch(url, {
      method: "POST",
      headers: {
        "Content-type": "application/json",
      },
      body: JSON.stringify({ ...payload }),
    });

    const responseData = await response.json();
    if (!response.ok) {
      const error = new Error(
        responseData.message || "Failed to update password"
      );
      throw error;
    }

    return responseData;
  },
  async resetPassword(context, payload) {
    let url = `${ip}/admin/reset-password/reset`;
    const response = await fetch(url, {
      method: "POST",
      headers: {
        "Content-type": "application/json",
      },
      body: JSON.stringify({ ...payload }),
    });

    const responseData = await response.json();
    if (!response.ok) {
      const error = new Error(
        responseData.message || "Failed to update password"
      );
      throw error;
    }

    return responseData;
  },
  async resetPasswordVerify(context, payload) {
    let url = `${ip}/admin/reset-password/verify?tkn=${payload.tkn}`;
    const response = await fetch(url, {
      method: "GET",
      headers: {
        "Content-type": "application/json",
      },
    });

    const responseData = await response.json();
    if (!response.ok) {
      const error = new Error(
        responseData.message || "Failed to update password"
      );
      throw error;
    }

    return responseData;
  },
  //
  async getUsers() {
    const token = localStorage.getItem("token");
    let url = `${ip}/admin/users`;

    const response = await fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });

    const responseData = await response.json();
    if (!response.ok) {
      const error = new Error(responseData.message || "Failed to Authenticate");
      throw error;
    }
    return responseData;
  },
  async getCompanies() {
    const token = localStorage.getItem("token");
    let url = `${ip}/admin/companies`;

    const response = await fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });

    const responseData = await response.json();
    if (!response.ok) {
      const error = new Error(responseData.message || "Failed to Authenticate");
      throw error;
    }
    return responseData;
  },
  async updateCompany(context, payload) {
    let url = `${ip}/admin/update-company/${payload.company}`;
    const token = localStorage.getItem("token");
    const response = await fetch(url, {
      method: "PUT",
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        ...payload,
      }),
    });

    const responseData = await response.json();
    if (!response.ok) {
      const error = new Error(responseData.message || "Failed to Authenticate");
      throw error;
    }

    return responseData;
  },
  async updateConnectedAccount(context, payload) {
    let url = `${ip}/admin/update-connected-account/${payload.company}`;
    const token = localStorage.getItem("token");
    const response = await fetch(url, {
      method: "PUT",
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        ...payload,
      }),
    });

    const responseData = await response.json();
    if (!response.ok) {
      const error = new Error(responseData.message || "Failed to Authenticate");
      throw error;
    }

    return responseData;
  },
  async reactivateUser(context, payload) {
    let url = `${ip}/admin/reactivate-account/${payload.user}`;
    const token = localStorage.getItem("token");
    const response = await fetch(url, {
      method: "PUT",
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        ...payload,
      }),
    });

    const responseData = await response.json();
    if (!response.ok) {
      const error = new Error(responseData.message || "Failed to Authenticate");
      throw error;
    }

    return responseData;
  },
  async deactivateUser(context, payload) {
    let url = `${ip}/admin/deactivate-account/${payload.user}`;
    const token = localStorage.getItem("token");
    const response = await fetch(url, {
      method: "PUT",
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        ...payload,
      }),
    });

    const responseData = await response.json();
    if (!response.ok) {
      const error = new Error(responseData.message || "Failed to Authenticate");
      throw error;
    }

    return responseData;
  },
  async reactivateCompany(context, payload) {
    let url = `${ip}/admin/reactivate-company/${payload.company}`;
    const token = localStorage.getItem("token");
    const response = await fetch(url, {
      method: "PUT",
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        ...payload,
      }),
    });

    const responseData = await response.json();
    if (!response.ok) {
      const error = new Error(responseData.message || "Failed to Authenticate");
      throw error;
    }

    return responseData;
  },
  async deactivateCompany(context, payload) {
    let url = `${ip}/admin/deactivate-company/${payload.company}`;
    const token = localStorage.getItem("token");
    const response = await fetch(url, {
      method: "PUT",
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        ...payload,
      }),
    });

    const responseData = await response.json();
    if (!response.ok) {
      const error = new Error(responseData.message || "Failed to Authenticate");
      throw error;
    }

    return responseData;
  },
  async addShareHolder(context, payload) {
    let url = `${ip}/admin/shareholder/${payload.company}`;
    const token = localStorage.getItem("token");
    const response = await fetch(url, {
      method: "POST",
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        ...payload,
      }),
    });

    const responseData = await response.json();
    if (!response.ok) {
      const error = new Error(responseData.message || "Failed to Authenticate");
      throw error;
    }

    return responseData;
  },
  async deleteShareHolder(context, payload) {
    let url = `${ip}/admin/shareholder/${payload.id}`;
    const token = localStorage.getItem("token");
    const response = await fetch(url, {
      method: "DELETE",
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        ...payload,
      }),
    });

    const responseData = await response.json();
    if (!response.ok) {
      const error = new Error(responseData.message || "Failed to Authenticate");
      throw error;
    }

    return responseData;
  },
  async updateShareHolder(context, payload) {
    let url = `${ip}/admin/shareholder/${payload.id}`;
    const token = localStorage.getItem("token");
    const response = await fetch(url, {
      method: "PUT",
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        ...payload,
      }),
    });

    const responseData = await response.json();
    if (!response.ok) {
      const error = new Error(responseData.message || "Failed to Authenticate");
      throw error;
    }

    return responseData;
  },
  async getCompany(context, payload) {
    let url = `${ip}/admin/company/${payload}`;
    const token = localStorage.getItem("token");
    const response = await fetch(url, {
      method: "GET",
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });

    const responseData = await response.json();
    if (!response.ok) {
      const error = new Error(responseData.message || "Failed to Authenticate");
      throw error;
    }

    return responseData;
  },
  async getConnectedAccountDB(context, payload) {
    let url = `${ip}/admin/connected-account/${payload}`;
    const token = localStorage.getItem("token");
    const response = await fetch(url, {
      method: "GET",
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });

    const responseData = await response.json();
    if (!response.ok) {
      const error = new Error(responseData.message || "Failed to Authenticate");
      throw error;
    }

    return responseData;
  },
  async buyPackage(context, payload) {
    let url = `${ip}/api/company/buy-package`;
    const details = {
      ...payload,
    };

    const response = await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(details),
    });

    const responseData = await response.json();
    if (!response.ok) {
      const error = new Error(responseData.message || "Failed to Authenticate");
      throw error;
    }
    return responseData.link;
  },
  async getShareholders(context, payload) {
    let url = `${ip}/admin/shareholders/${payload}`;
    const token = localStorage.getItem("token");
    const response = await fetch(url, {
      method: "GET",
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });

    const responseData = await response.json();
    if (!response.ok) {
      const error = new Error(responseData.message || "Failed to Authenticate");
      throw error;
    }
    return responseData;
  },
  async getDocuments(context, payload) {
    let url = `${ip}/admin/documents/${payload}`;
    const token = localStorage.getItem("token");
    const response = await fetch(url, {
      method: "GET",
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });

    const responseData = await response.json();
    if (!response.ok) {
      const error = new Error(responseData.message || "Failed to Authenticate");
      throw error;
    }
    return responseData;
  },
  async postSignatureDocument(context, payload) {
    let url = `${ip}/admin/documents/signature/${payload.company}`;
    const token = localStorage.getItem("token");
    const response = await fetch(url, {
      method: "POST",
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        ...payload,
      }),
    });

    const responseData = await response.json();
    if (!response.ok) {
      const error = new Error(responseData.message || "Failed to Authenticate");
      throw error;
    }
    return responseData;
  },
  async postDocument(context, payload) {
    let url = `${ip}/admin/documents/${payload.company}`;
    const token = localStorage.getItem("token");
    const response = await fetch(url, {
      method: "POST",
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        ...payload,
      }),
    });

    const responseData = await response.json();
    if (!response.ok) {
      const error = new Error(responseData.message || "Failed to Authenticate");
      throw error;
    }
    return responseData;
  },
  async search(context, payload) {
    let url = `${ip}/admin/search`;
    const token = localStorage.getItem("token");
    const response = await fetch(url, {
      method: "POST",
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        ...payload,
      }),
    });

    const responseData = await response.json();
    if (!response.ok) {
      const error = new Error(responseData.message || "Failed to Authenticate");
      throw error;
    }
    return responseData;
  },
  async getExpiring() {
    let url = `${ip}/admin/expiring`;
    const token = localStorage.getItem("token");
    const response = await fetch(url, {
      method: "GET",
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });

    const responseData = await response.json();
    if (!response.ok) {
      const error = new Error(responseData.message || "Failed to Authenticate");
      throw error;
    }
    return responseData;
  },
  async getAllTx() {
    let url = `${ip}/admin/transactions`;
    const token = localStorage.getItem("token");
    const response = await fetch(url, {
      method: "GET",
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });

    const responseData = await response.json();
    if (!response.ok) {
      const error = new Error(responseData.message || "Failed to Authenticate");
      throw error;
    }
    return responseData;
  },
  async getAllCards() {
    let url = `${ip}/admin/cards`;
    const token = localStorage.getItem("token");
    const response = await fetch(url, {
      method: "GET",
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });

    const responseData = await response.json();
    if (!response.ok) {
      const error = new Error(responseData.message || "Failed to Authenticate");
      throw error;
    }
    return responseData;
  },
  async getAllTopups() {
    let url = `${ip}/admin/topups`;
    const token = localStorage.getItem("token");
    const response = await fetch(url, {
      method: "GET",
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });

    const responseData = await response.json();
    if (!response.ok) {
      const error = new Error(responseData.message || "Failed to Authenticate");
      throw error;
    }
    return responseData;
  },
  async getCard(context, payload) {
    let url = `${ip}/admin/get-card?companyId=${payload}`;
    const token = localStorage.getItem("token");
    const response = await fetch(url, {
      method: "GET",
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });

    const responseData = await response.json();
    if (!response.ok) {
      const error = new Error(responseData.message || "Failed to Authenticate");
      throw error;
    }
    return responseData;
  },

  async getPhysicalCard(context, payload) {
    let url = `${ip}/admin/get-phyiscal-card?cardId=${payload}`;
    const token = localStorage.getItem("token");
    const response = await fetch(url, {
      method: "GET",
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });

    const responseData = await response.json();
    if (!response.ok) {
      const error = new Error(responseData.message || "Failed to Authenticate");
      throw error;
    }
    return responseData;
  },
  async getConnectedAccount(context, payload) {
    let url = `${ip}/admin/get-connected-account?companyId=${payload}`;
    const token = localStorage.getItem("token");
    const response = await fetch(url, {
      method: "GET",
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });

    const responseData = await response.json();
    if (!response.ok) {
      const error = new Error(responseData.message || "Failed to Authenticate");
      throw error;
    }
    return responseData;
  },
  async loadDashboardTransactions() {
    let url = `${ip}/admin/dashboard/transactions`;
    const token = localStorage.getItem("token");
    const response = await fetch(url, {
      method: "GET",
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });

    const responseData = await response.json();
    if (!response.ok) {
      const error = new Error(responseData.message || "Failed to Authenticate");
      throw error;
    }
    return responseData;
  },
  async loadDashboardSummary() {
    let url = `${ip}/admin/dashboard/summary`;
    const token = localStorage.getItem("token");
    const response = await fetch(url, {
      method: "GET",
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });

    const responseData = await response.json();
    if (!response.ok) {
      const error = new Error(responseData.message || "Failed to Authenticate");
      throw error;
    }
    return responseData;
  },
  async loadDashboardNotifications() {
    let url = `${ip}/admin/dashboard/notifications`;
    const token = localStorage.getItem("token");
    const response = await fetch(url, {
      method: "GET",
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });

    const responseData = await response.json();
    if (!response.ok) {
      const error = new Error(responseData.message || "Failed to Authenticate");
      throw error;
    }
    return responseData;
  },
  async fetchSender(context, payload) {
    let url = `${ip}/admin/dashboard/sender/${payload}`;
    const token = localStorage.getItem("token");
    const response = await fetch(url, {
      method: "GET",
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });

    const responseData = await response.json();
    if (!response.ok) {
      const error = new Error(responseData.message || "Failed to Authenticate");
      throw error;
    }
    return responseData;
  },
  async messageRead(context, payload) {
    let url = `${ip}/admin/messages/read/${payload}`;
    const token = localStorage.getItem("token");
    const response = await fetch(url, {
      method: "GET",
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });

    const responseData = await response.json();
    if (!response.ok) {
      const error = new Error(responseData.message || "Failed to Authenticate");
      throw error;
    }
    return;
  },
  async countNotifications() {
    let url = `${ip}/admin/dashboard/notifications/count`;
    const token = localStorage.getItem("token");
    const response = await fetch(url, {
      method: "GET",
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });

    const responseData = await response.json();
    if (!response.ok) {
      const error = new Error(responseData.message || "Failed to Authenticate");
      throw error;
    }
    return responseData;
  },
  async sendMessage(context, payload) {
    const token = localStorage.getItem("token");
    let url = `${ip}/api/message`;
    const details = {
      ...payload,
    };

    const response = await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(details),
    });

    const responseData = await response.json();
    if (!response.ok) {
      const error = new Error(responseData.message || "Failed to Authenticate");
      throw error;
    }
    return responseData;
  },
  async sendUserMessage(context, payload) {
    const token = localStorage.getItem("token");
    let url = `${ip}/admin/message/${payload.user}`;
    const details = {
      ...payload,
    };

    const response = await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(details),
    });

    const responseData = await response.json();
    if (!response.ok) {
      const error = new Error(responseData.message || "Failed to Authenticate");
      throw error;
    }
    return responseData;
  },
  async toggleChat(context) {
    context.commit("toggleChat");
  },
  async loadUserMessages(context, payload) {
    const token = localStorage.getItem("token");
    let url = `${ip}/admin/messages/${payload}`;

    const response = await fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });

    const responseData = await response.json();
    if (!response.ok) {
      const error = new Error(responseData.message || "Failed to Authenticate");
      throw error;
    }
    return responseData;
  },
  async userTransactions(context, payload) {
    const token = localStorage.getItem("token");
    let url = `${ip}/admin/transactions/${payload}`;

    const response = await fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });

    const responseData = await response.json();
    if (!response.ok) {
      const error = new Error(responseData.message || "Failed to Authenticate");
      throw error;
    }
    return responseData;
  },
  async changePassword(context, payload) {
    let url;

    if (payload.role === "owner") {
      url = `${ip}/auth/update`;
    } else if (payload.role === "sales rep") {
      url = `${ip}/auth/update/salesrep`;
    } else {
      url = `${ip}/api/admin/update`;
    }
    const token = localStorage.getItem("token");
    const response = await fetch(url, {
      method: "PUT",
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        password: payload.password,
        oldPassword: payload.oldPassword,
      }),
    });

    const responseData = await response.json();
    if (!response.ok) {
      const error = new Error(responseData.message || "Failed to Authenticate");
      throw error;
    }

    return;
  },
  autoLogout(context) {
    context.dispatch("logout");
    context.commit("setAutoLogout");
  },

  async cardApproval(context, payload) {
    let url = `${ip}/admin/company/approval/card`;
    const token = localStorage.getItem("token");

    const response = await fetch(url, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        companyId: payload.parentCompany._id,
        userId: payload.user,
        cardId: payload._id,
        transactionStatus: payload.status,
        isApproved: payload.isApproved,
        cadence: payload.cadence,
      }),
    });

    const responseData = await response.json();
    if (!response.ok) {
      const error = new Error(responseData.message || "Something went wrong!");
      throw error;
    }
    return responseData;
  },

  async createStripeAccount(context, payload) {
    let url = `${ip}/admin/company/create-stripe-account`;
    const token = localStorage.getItem("token");
    const response = await fetch(url, {
      method: "POST",
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        companyId: payload,
      }),
    });

    const responseData = await response.json();
    if (!response.ok) {
      const error = new Error(responseData.message || "Something went wrong!");
      throw error;
    }
    return responseData;
  },

  async createVirutalCard(context, payload) {
    let url = `${ip}/admin/company/create-card-holder`;
    const token = localStorage.getItem("token");
    const name = `${payload.firstname} ${payload.lastname}`;
    const response = await fetch(url, {
      method: "POST",
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      // Put the required data this is for testing only.
      body: JSON.stringify({
        companyAccountId: payload._id,
        companyId: payload.companyId,
        stripeId: payload.stripeAccountId,
        first_name: payload.firstname,
        last_name: payload.lastname,
        account_holder_name: name,
        email: payload.email,
        phone_number: payload.companyPhone_number,
        line1: payload.companyLine1,
        city: payload.companyCity,
        postal_code: payload.companyPostalCode,
        country: payload.countryCode,
        account_number: payload.bankAccountNumber,
      }),
    });

    const responseData = await response.json();
    if (!response.ok) {
      const error = new Error(responseData.message || "Something went wrong!");
      throw error;
    }
    return responseData;
  },

  async cardActivation(context, payload) {
    let url = `${ip}/admin/cards/card-activation`;
    const token = localStorage.getItem("token");
    const response = await fetch(url, {
      method: "PUT",
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        id: payload._id,
        companyId: payload.parentCompany,
        cardId: payload.cardId,
        cardStatus: payload.cardStatus,
        stripeAccountId: payload.accountStripeId,
        userId: payload.user,
      }),
    });

    const responseData = await response.json();
    if (!response.ok) {
      const error = new Error(responseData.message || "Something went wrong!");
      throw error;
    }
    return responseData;
  },

  // wait for stripe to reply
  async confirmPhysicalCard(context, payload) {
    let url = `${ip}/admin/create-physical-card`;
    const token = localStorage.getItem("token");
    const response = await fetch(url, {
      method: "POST",
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        id: payload._id,
        companyId: payload.parentCompany._id,
        pin: payload.pin,
      }),
    });

    const responseData = await response.json();
    if (!response.ok) {
      const error = new Error(responseData.message || "Something went wrong!");
      throw error;
    }
    return responseData;
  },

  async controlFees(context, payload) {
    let url = `${ip}/admin/update-control-fees`;
    const token = localStorage.getItem("token");
    const response = await fetch(url, {
      method: "PUT",
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        physicalCard: payload.physical,
        shipping: payload.shipping,
        virtualCard: payload.virtual,
      }),
    });

    const responseData = await response.json();
    if (!response.ok) {
      const error = new Error(responseData.message || "Something went wrong!");
      throw error;
    }
    return responseData;
  },

  async getFees(context, payload) {
    let url = `${ip}/admin/get-fees`;
    const token = localStorage.getItem("token");
    const response = await fetch(url, {
      method: "GET",
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });

    const responseData = await response.json();
    if (!response.ok) {
      const error = new Error(responseData.message || "Something went wrong!");
      throw error;
    }
    return responseData;
  },

  async setTopupStatus(context, payload) {
    let url = `${ip}/admin/topups-status?id=${payload.id}`;
    const token = localStorage.getItem("token");
    const response = await fetch(url, {
      method: "PUT",
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        id: payload.id,
        status: payload.status,
        user_id: payload.user_id,
        amount: payload.amount,
      }),
    });

    const responseData = await response.json();
    if (!response.ok) {
      const error = new Error(responseData.message || "Something went wrong!");
      throw error;
    }
    return responseData;
  },

  async setTransactionStatus(context, payload) {
    let url = `${ip}/admin/transaction-status?id=${payload.id}`;
    const token = localStorage.getItem("token");
    const response = await fetch(url, {
      method: "PUT",
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        id: payload.id,
        status: payload.status,
      }),
    });

    const responseData = await response.json();
    if (!response.ok) {
      const error = new Error(responseData.message || "Something went wrong!");
      throw error;
    }
    return responseData;
  },
};
