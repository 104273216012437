import store from "../store";

const routes = [
  {
    path: "/",
    redirect: "/dashboard",
    component: () => import("@/layout/Layout.vue"),
    children: [
      {
        path: "/dashboard",
        name: "dashboard",
        component: () => import("@/views/Dashboard.vue"),
      },
      {
        path: "/users",
        name: "users",
        component: () => import("@/views/Users.vue"),
      },
      {
        path: "/users/:id",
        props: true,
        name: "user",
        component: () => import("@/views/User.vue"),
      },
      {
        path: "/companies",
        name: "companies",
        component: () => import("@/views/Companies.vue"),
      },
      {
        path: "/companies/:id/:registrar",
        props: true,
        name: "company",
        component: () => import("@/views/Company.vue"),
      },
      {
        path: "/companies/edit/:id",
        props: true,
        name: "editcompany",
        component: () => import("@/views/EditCompany.vue"),
      },
      {
        path: "/connected-account/edit/:id",
        props: true,
        name: "editconnectedaccount",
        component: () => import("@/views/EditConnectedAccount.vue"),
      },
      {
        path: "/transactions",
        name: "transactions",
        component: () => import("@/views/Transactions.vue"),
      },
      {
        path: "/cards",
        name: "cards",
        component: () => import("@/views/Cards.vue"),
      },
      {
        path: "/top-ups",
        name: "top-ups",
        component: () => import("@/views/Topups.vue"),
      },
      {
        path: "/fees",
        name: "fees",
        component: () => import("@/views/Fees.vue"),
      },
      {
        path: "/expiring",
        name: "expiring",
        component: () => import("@/views/Expiring.vue"),
      },
      {
        path: "/messages",
        name: "messages",
        component: () => import("@/views/apps/chat/Chat.vue"),
      },
      {
        path: "/account",
        name: "account",
        component: () => import("@/views/crafted/account/Account.vue"),
        children: [
          {
            path: "profile",
            name: "account-profile",
            component: () => import("@/views/crafted/account/Profile.vue"),
          },
          {
            path: "settings",
            name: "account-settings",
            component: () => import("@/views/crafted/account/Settings.vue"),
          },
        ],
      },
    ],
    beforeEnter: (to, from, next) => {
      const first = store.getters["auth/isAuthenticated"];
      if (!first) {
        next("/sign-in");
        return;
      }
      store.dispatch("auth/hasExpired").then((response) => {
        if (response) {
          next("/sign-in");
          return;
        }
      });
      next();
    },
  },
  {
    path: "/sign-in",
    name: "sign-in",
    component: () =>
      import("@/views/crafted/authentication/basic-flow/SignIn.vue"),
    beforeEnter: (to, from, next) => {
      const first = store.getters["auth/isAuthenticated"];
      if (first) {
        return next("/dashboard");
      }
      store.dispatch("auth/hasExpired").then((response) => {
        if (!response) {
          return next("/dashboard");
        }
      });
      next();
    },
  },
  {
    path: "/",
    component: () => import("@/components/page-layouts/Auth.vue"),
    children: [
      {
        path: "/sign-up",
        redirect: "/sign-in",
        name: "sign-up",
        component: () =>
          import("@/views/crafted/authentication/basic-flow/SignUp.vue"),
      },
      {
        path: "/password-reset",
        name: "password-reset",
        component: () =>
          import("@/views/crafted/authentication/basic-flow/PasswordReset.vue"),
      },
      {
        path: "/verify",
        name: "Verify",
        component: () =>
          import("@/views/crafted/authentication/basic-flow/Verify.vue"),
      },
    ],
    beforeEnter: (to, from, next) => {
      const first = store.getters["auth/isAuthenticated"];
      if (first) {
        return next("/dashboard");
      }
      store.dispatch("auth/hasExpired").then((response) => {
        if (!response) {
          return next("/dashboard");
        }
      });
      next();
    },
  },
  {
    path: "/multi-step-sign-up",
    name: "multi-step-sign-up",
    component: () =>
      import("@/views/crafted/authentication/MultiStepSignUp.vue"),
  },
  {
    // the 404 route, when none of the above matches
    path: "/404",
    name: "404",
    component: () => import("@/views/crafted/authentication/Error404.vue"),
  },
  {
    path: "/500",
    name: "500",
    component: () => import("@/views/crafted/authentication/Error500.vue"),
  },
  {
    path: "/:pathMatch(.*)*",
    redirect: "/404",
  },
];

export default routes;
